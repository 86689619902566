// Форма
.form {
  display       : flex;
  flex-direction: column;

  &-label {
    display       : flex;
    flex-direction: column;
  }

  &-wrapper {
    display : block;
    position: relative;
    width   : 100%;
  }

  &-icon {
    display        : flex;
    align-items    : center;
    justify-content: center;
    position       : absolute;
    top            : 0;
    bottom         : 0;
    width          : var(--size-lg);
    height         : var(--size-lg);

    &:not(&--pointer) {
      pointer-events: none;
    }

    &--pointer {
      pointer-events: all;
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  &-error {
    display         : flex;
    align-items     : center;
    justify-content : center;
    text-align      : center;
    position        : absolute;
    left            : 0;
    right           : 0;
    bottom          : -16px;
    height          : 16px;
    font-size       : var(--text-12);
    color           : var(--color-white);
    background-color: var(--color-red);
    border-radius   : 8px;
  }
}