// Главный блок
.main {
  min-height      : 620px;
  background-image: linear-gradient(96.15deg, var(--color-black-30) 14.08%, var(--color-none) 80.44%);

  &__lines {
    transform: rotate(-60deg);
    max-width: 850px;
    top      : -200px;
    left     : calc(95vw - 850px);
  }

  &__info {
    max-width: 590px;
  }

  &__pic {
    max-width: 540px;
  }

  @media(max-width: $lg) {
    &__content {
      flex-direction: column;
    }

    &__pic {
      order    : 1;
      max-width: 360px;
    }

    &__info {
      order: 2;
    }
  }

  @media(max-width: $md) {
    & {
      padding-top: 200px;
    }

    &__lines {
      left     : 100px;
      max-width: 100%;
    }

    &__info {
      max-width: 100%;
    }
  }

  @media(max-width: $sm) {
    &__form {
      flex-direction: column;
      align-items   : flex-start;
    }
  }
}

// Сервис
.service {
  &__card {
    max-width: 1050px;
  }

  &__pic {
    max-width: 540px;
  }

  &__bg {
    max-width: 860px;
    top      : 20px;
    left     : -180px;
  }

  &__price {
    max-width       : 250px;
    background-image: linear-gradient(96.15deg, var(--color-black-30) 14.08%, var(--color-none) 57.28%);
  }

  @media(max-width: $lg) {
    &__content {
      flex-direction: column;
      align-items   : flex-start;
    }

    &__bg {
      max-width: 100%;
      left     : auto;
      top      : 150px;
      right    : -200px;
    }

    &__pic {
      max-width: 420px;
    }
  }

  @media(max-width: $md) {
    &__price {
      max-width: 100%;
    }
  }
}

// Продукция
.products {
  &__pic {
    min-width: 150px;
    width    : 150px;
  }

  @media(max-width: $md) {
    &__content {
      flex-direction: column;
    }

    &__info {
      align-items    : center;
      justify-content: center;
      text-align     : center;
    }
  }
}

// Рассчет
.calculation {
  &__item {
    background: linear-gradient(269.8deg, var(--color-white-20) 0.16%, var(--color-none) 59.13%), var(--color-green);
  }

  &__pic {
    max-width    : 500px;
    border-radius: 0px 24px 24px 0px;
  }
}

// Обратная связь
.feedback {
  width: 400px;
}