// Изображение
.pic {
  display : block;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
  }

  &--segment::before {
    padding-top: 50%;
  }

  &--rect::before {
    padding-top: 75%;
  }

  &--square::before {
    padding-top: 100%;
  }

  &--photo::before {
    padding-top: 125%;
  }

  &__img {
    position: absolute;
    inset   : 0;
    width   : 100%;
    height  : 100%;

    &:not(&--sd) {
      object-fit: cover;
    }

    &:is(&--sd) {
      object-fit: scale-down;
    }

    &--rise {
      transition: transform 0.3s linear;
    }
  }

  @media(hover) {
    &:hover &__img--rise {
      transform: scale(1.1);
    }
  }
}